import { JL } from 'jsnlog';
import { jwtDecode } from 'jwt-decode';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import { v4 as generateUUID } from 'uuid';

import { getBestToken } from '@/ducks/auth/selectors';
import { routes } from '@/ducks/routes';
import { env } from '@/environment';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import { HttpError, InterruptionError } from '@/helpers/api/utils';
import { isServerSide } from '@/helpers/isServerSide';
import { removeStoredValue } from '@/helpers/util';

const checkIfUnauthorizedError = (response) => response.status === 401;

const AUTH_HEADER = 'Authorization';

const handleUnauthorizedError = async (response, request, url) => {
  const isUnauthorizedError = checkIfUnauthorizedError(response);

  if (!isUnauthorizedError) {
    return response;
  }

  if (request.headers[AUTH_HEADER]) {
    try {
      const { userid } = jwtDecode(request.headers[AUTH_HEADER]);
      if (userid && !isServerSide()) {
        removeStoredValue('Authorization');
      }
    } catch (e) {
      console.error(e);
    }
  }

  const token = await AppAuthenticator.getInstance().getFreshAccessToken({ tokenType: TokenType.guest });

  const newResponse = await fetch(url, {
    ...request,
    headers: {
      ...request.headers,
      [AUTH_HEADER]: `bearer ${token}`,
    },
  });

  if (!newResponse.ok && checkIfUnauthorizedError(newResponse)) {
    routes.accounts.signin.go();
  }

  return newResponse;
};

export const getDefaultHeaders = async (correlationId, config) => {
  const { language, next, noAuth, token } = config ?? {};
  let authHeader;
  if (!noAuth) {
    authHeader = config?.headers?.[AUTH_HEADER];
    if (!authHeader) {
      const authToken = token || (!isServerSide() ? await getBestToken() : undefined);
      if (authToken) authHeader = `bearer ${authToken}`;
    }
  }

  let cache = {};
  if (!next?.revalidate) {
    cache = {
      'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0',
      CorrelationId: correlationId,
      Pragma: 'no-cache',
    };
  }

  return {
    Accept: 'application/json',
    ...(language ? { 'Accept-Language': language } : {}),
    'Content-Type': 'application/json',
    ...cache,
    ...(authHeader ? { [AUTH_HEADER]: authHeader } : undefined),
    'X-Requested-With': 'XMLHttpRequest',
  };
};

const getCurrentHeaders = (config) => {
  const { headers = {} } = config;
  return headers;
};

const getConfigDetails = (config) => {
  return {
    baseURL: config.baseURL,
    headers: {
      Accept: config.headers.Accept,
      CorrelationId: config.headers.CorrelationId,
    },
    method: config.method,
    timeout: config.timeout,
    url: config.url,
  };
};

export const getResponseDetails = (response, request, result, config) => {
  const getResponseData = () => {
    if (config.logShortResponseData) {
      let data = typeof result === 'string' ? result : JSON.stringify(result);
      return data.length > 1000 ? data.substring(0, 1000) : data;
    }
    return result;
  };

  return {
    config: {
      baseURL: config.url,
      headers: {
        Accept: config.headers.Accept,
        CorrelationId: config.headers.CorrelationId,
      },
      method: config.method,
      url: response.url,
    },
    data: getResponseData(),
    headers: response.headers,
    request: isServerSide() ? null : request,
    status: response.status,
    statusText: response.statusText,
  };
};

const extractResponseBody = async (response) => {
  let body = await response.text();

  try {
    return JSON.parse(body);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return body;
    }

    throw e;
  }
};

const getUrl = (config) => {
  const url = new URL(`${trimEnd(config.baseURL, '/')}/${trimStart(config.url, '/')}`);
  if (config.params) {
    new URLSearchParams(config.params).forEach((value, key) => url.searchParams.append(key, value));
  }
  return url.href;
};

export const api = {
  delete(url, config = {}) {
    return this.request({
      ...config,
      method: 'DELETE',
      url,
    });
  },
  get(url, config = {}) {
    return this.request({
      ...config,
      method: 'GET',
      url,
    });
  },
  patch(url, data, config = {}) {
    return this.request({
      ...config,
      body: JSON.stringify(data),
      data,
      method: 'PATCH',
      url,
    });
  },
  post(url, data, config = {}) {
    return this.request({
      ...config,
      body: JSON.stringify(data),
      data,
      method: 'POST',
      url,
    });
  },
  put(url, data, config = {}) {
    return this.request({
      ...config,
      body: JSON.stringify(data),
      data,
      method: 'PUT',
      url,
    });
  },
  async request(configuration) {
    const { onResponseHeaders, validationError, validator, ...config } = configuration;
    // TODO: BFF HTTP -> HTTPS

    let messageDetail;
    // req logging
    const correlationId = generateUUID();

    // headers
    config.headers = {
      ...(await getDefaultHeaders(correlationId, config)),
      ...getCurrentHeaders(config),
    };
    // if defaults will be overriden
    config.baseURL = config.baseURL ?? env.REST_BFF_URL;

    const isValidated = validator?.({ args: configuration?.data });
    if (validator && !isValidated) {
      console.warn(messageDetail.message, configuration?.data);
      messageDetail = {
        message: 'invalid arguments when calling endpoint',
        messageCode: '9001',
        severity: 'WARN',
      };
      JL('BV').warn({
        correlationId,
        message: getConfigDetails(config),
        messageDetail,
      });
      return null;
    }

    // req start
    messageDetail = {
      message: 'common service request message',
      messageCode: '5001',
      severity: 'INFO',
    };
    JL('BV').info({
      correlationId,
      message: getConfigDetails(config),
      messageDetail,
    });

    try {
      if (validationError) {
        throw validationError;
      }

      const request = {
        body: config.body,
        headers: config.headers,
        method: config.method,
        next: config.next,
      };

      const url = getUrl(config);

      let response;
      try {
        response = await fetch(url, request);
      } catch (exc) {
        throw InterruptionError.recognize(exc);
      }

      if (!response.ok) {
        response = await handleUnauthorizedError(response, request, url);
        if (!response.ok) {
          throw new HttpError(response, request, config, await extractResponseBody(response));
        }
      }

      onResponseHeaders?.(response.headers);

      const result = await extractResponseBody(response);

      messageDetail = {
        message: 'common service response message',
        messageCode: '5002',
        severity: 'INFO',
      };
      JL('BV').info({
        correlationId,
        message: getResponseDetails(response, request, result, config),
        messageDetail,
      });

      return result;
    } catch (err) {
      messageDetail = {
        message: 'common service request error message',
        messageCode: '9001',
        severity: 'ERROR',
      };
      JL('BV').error({ correlationId, message: err, messageDetail });
      throw err;
    }
  },
};
