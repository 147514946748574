import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';

import type { CoreRequestConfig } from '@/helpers/api/utils';
import type { CruiseCommonSlice, Package, PackagesData } from '@/infra/types/voyageInfo/package';
import type { AppDispatch, AppGetState, RootState } from '@/store';

import { selectAccessKeyPromoCode } from '@/ducks/accessKeys/accessKeys';
import { checkAccessKeyAction } from '@/ducks/accessKeys/actions';
import { selectBookingSourcePayload } from '@/ducks/api/selectors';
import { selectDefaultPackageCodes } from '@/ducks/common/lookup/selectors';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import {
  selectCabinType,
  selectCurrencyCode,
  selectFromDate,
  selectMetaType,
  selectSailors,
  selectToDate,
  selectVoyageIds,
} from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import {
  type TFilteredPackages,
  type TGenericCategories,
  type TMainPackages,
} from '@/ducks/pages/chooseVoyage/chooseVoyageNew';
import { isLockItInFlowCabin } from '@/ducks/programs/lockItInRate/isLockItCabin';
import { fetchVoyageListData } from '@/helpers/api/app';
import { checkForValueType } from '@/helpers/util/misc';
import { FiltersSortType } from '@/infra/types/common/filters';
import { type TOptional } from '@/types/common';

import {
  NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
  NEW_CHOOSE_VOYAGE_SEARCH_FAILURE,
  NEW_CHOOSE_VOYAGE_SEARCH_START,
  NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS,
  NEW_GENERIC_CATEGORY_CODE_DATA,
} from '../actionTypes';
import { getSailingsFromPackages } from '../getters';
import { getFilteredPackages, selectIsMultipleVoyageFilterActive, selectVoyagesApiPayload } from '../selectors';
import { sortSailings } from '../utility';

const getDefaultPayload = (state: RootState) => {
  const filterOptions = getFiltersOptions(state);
  const currencyCode = selectDefaultCurrencyCode(state);
  const defaultPackageCodes = selectDefaultPackageCodes(state);
  return {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: filterOptions.defaultFilters.sailors,
            },
          ],
        },
      ],
      currencyCode,
      defaultPackageCodes,
      preferences: [],
      sailingDateRange: [
        {
          end: filterOptions.defaultEndDate,
          start: filterOptions.defaultStartDate,
        },
      ],
    },
  };
};

export const buildFetchVoyageListDataPayload = (
  props: { accessKeys?: string[]; dateFrom?: string; dateTo?: string } = {},
  state: RootState,
) => {
  const metaType = selectMetaType(state);
  const cabinType = selectCabinType(state);
  const dateFrom = props.dateFrom || selectFromDate(state);
  const dateTo = props.dateTo || selectToDate(state);
  const sailors = selectSailors(state);
  const currencyCode = selectCurrencyCode(state);
  const defaultPackageCodes = selectDefaultPackageCodes(state) || [];

  let preferencesValue: { categoryCode?: string; metaCode?: string }[] = [];

  if (isLockItInFlowCabin(cabinType)) {
    preferencesValue = [{ categoryCode: cabinType }];
  } else if (metaType) {
    preferencesValue = [{ metaCode: metaType }];
  }

  const payload = {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: props.accessKeys || [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: sailors,
            },
          ],
        },
      ],
      currencyCode,
      defaultPackageCodes,
      isDefaultSearch: false,
      preferences: preferencesValue,
      sailingDateRange: [
        {
          end: dateTo,
          start: dateFrom,
        },
      ],
    },
    ...selectBookingSourcePayload(state),
  };

  if (isEqual(payload, getDefaultPayload(state))) {
    payload.searchQualifier.isDefaultSearch = true;
  }

  return payload;
};

export type TPackagesAndSailings = TGenericCategories & {
  filteredPackages: TFilteredPackages;
  isLoading?: boolean;
  mainPackages: TMainPackages;
};

export const fetchPackages =
  (config: CoreRequestConfig = {}) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<TOptional<TPackagesAndSailings>> => {
    let state = getState();

    try {
      const cabinType = selectCabinType(state);
      const metaType = selectMetaType(state);
      const isMultipleVoyageFilterActive = selectIsMultipleVoyageFilterActive(state);
      const voyageIds = selectVoyageIds(state);

      const accessKey = selectAccessKeyPromoCode(state) || (await dispatch(checkAccessKeyAction(config)))?.promoCode;

      state = getState();
      const voyagesApiPayload = buildFetchVoyageListDataPayload(accessKey ? { accessKeys: [accessKey] } : {}, state);

      if (isEqual(voyagesApiPayload, selectVoyagesApiPayload(state))) return;

      dispatch({ payload: voyagesApiPayload, type: NEW_CHOOSE_VOYAGE_SEARCH_START });

      const results = (await fetchVoyageListData(voyagesApiPayload, {
        ...config,
        logShortResponseData: true,
      })) as CruiseCommonSlice['packagesData'];

      if (results.defaultPackages) {
        results.defaultPackages = sortSailings(results.defaultPackages, FiltersSortType.date) as Package[];
      }

      if (checkForValueType(results)) {
        const { packages } = results;
        const sailings = getSailingsFromPackages(packages);
        if (checkForValueType(sailings)) {
          results.sailings = sailings;
        }
      }

      if (cabinType === null || !isEmpty(metaType)) {
        dispatch({
          payload: {
            defaultGenericCategoryCodes: results.defaultGenericCategoryCodes,
            genericCategoryCodes: results.genericCategoryCodes,
          },
          type: NEW_GENERIC_CATEGORY_CODE_DATA,
        });
      }

      let filteredResults: PackagesData;
      if (results && results.packages) {
        const filteredSailings = isMultipleVoyageFilterActive
          ? getSailingsFromPackages(results.packages!).filter((sailing) => voyageIds.indexOf(sailing.id) > -1)
          : results.sailings;
        filteredResults = {
          ...results,
          sailings: filteredSailings,
        };

        dispatch({
          payload: filteredResults,
          type: NEW_CHOOSE_VOYAGE_SEARCH_API_SUCCESS,
        });
      }

      const filteredPackages = dispatch(filterPackages());

      const {
        defaultGenericCategoryCodes = results.defaultGenericCategoryCodes,
        genericCategoryCodes = results.genericCategoryCodes,
        ...mainPackages
      } = filteredResults || {};
      return { defaultGenericCategoryCodes, filteredPackages, genericCategoryCodes, mainPackages, voyagesApiPayload };
    } catch (e) {
      console.error(e);
      dispatch({ type: NEW_CHOOSE_VOYAGE_SEARCH_FAILURE });
    }
  };

export const filterPackages =
  () =>
  (dispatch: AppDispatch, getState: AppGetState): TFilteredPackages => {
    const filteredPackages = getFilteredPackages(getState());
    const data: TFilteredPackages = { packages: filteredPackages };
    const sailings = getSailingsFromPackages(filteredPackages);
    if (sailings.length) {
      data.sailings = sailings;
    }

    dispatch({ payload: data, type: NEW_CHOOSE_VOYAGE_SEARCH_SUCCESS });

    return data;
  };
