import type { AppDispatch, AppGetState } from '@/store';

import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { routes } from '@/ducks/routes';
import { updateSearch } from '@/ducks/routes/history';
import { fetchAccessKeyDetailsData, validateAccessKey } from '@/helpers/api/app';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';

import {
  ACCESS_KEY_STATES,
  accessKeyClear,
  accessKeyDataIsReady,
  accessKeyDetailsError,
  accessKeyDetailsSuccess,
  accessKeyInited,
  accessKeyValidateError,
  accessKeyValidateSuccess,
  selectAccessKeySlice,
} from './accessKeys';
import { mapAccessKeysErrorCodes } from './errorCodes';

export const fetchAccessKeyDetailsAction =
  (accessKey: string, config = {}) =>
  async (dispatch: AppDispatch) => {
    try {
      const data = await fetchAccessKeyDetailsData(accessKey, config);
      dispatch(accessKeyDetailsSuccess(data));
    } catch (err) {
      dispatch(accessKeyDetailsError(err));
    }
  };

export const setAccessKeyErrorAction =
  ({ code, message }: { code?: number; message?: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(accessKeyValidateError(code ? mapAccessKeysErrorCodes(code).defaultMessage : message));
  };

export const validateAccessKeyAction =
  (accessKey: string, config = {}) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { accessKeyEnabled } = selectSettings(state);
    if (!accessKeyEnabled || !accessKey) {
      dispatch(accessKeyDataIsReady());
      return {};
    }

    const {
      accessKey: currentAccessKey,
      campaignKey,
      filterSailing,
      promoCode,
      state: status,
    } = selectAccessKeySlice(state) || {};
    const isAlreadyDone = accessKey === currentAccessKey && ACCESS_KEY_STATES.DETAIL === status;
    if (isAlreadyDone) return { accessKey, campaignKey, filterSailing, promoCode };

    try {
      dispatch(accessKeyInited(accessKey));

      const data = await validateAccessKey(accessKey, config);
      dispatch(accessKeyValidateSuccess({ ...data, source: 'URL' }));

      await dispatch(fetchAccessKeyDetailsAction(data.campaignKey, config));

      return data;
    } catch (err) {
      dispatch(setAccessKeyErrorAction({ code: err?.response ? err.response.status : null }));
      return { error: 'invalid access key' };
    }
  };

export const checkAccessKeyAndSwapToPromoCodeAction =
  (config = {}) =>
  async (dispatch: AppDispatch) => {
    const searchParams = getCurrentSearchParams();
    const data = await dispatch(checkAccessKeyAction(config));

    if (data.accessKey === searchParams.get(ACCESS_KEY_PARAM) && data.accessKey !== data.promoCode) {
      updateSearch({ ...searchParams, [ACCESS_KEY_PARAM]: data.promoCode });
    }

    return data;
  };

export const checkAccessKeyAction =
  (config = {}) =>
  async (dispatch: AppDispatch) => {
    const searchParams = getCurrentSearchParams();
    const accessKey = searchParams.get(ACCESS_KEY_PARAM);
    if (!accessKey) {
      dispatch(accessKeyDataIsReady());
      return {};
    }

    const data = await dispatch(validateAccessKeyAction(accessKey, config));
    return data;
  };

export const removeActiveAccessKeyAction =
  (removeFromSearchParams = true) =>
  (dispatch: AppDispatch) => {
    dispatch(accessKeyClear());
    if (removeFromSearchParams) {
      routes.searchWithout([ACCESS_KEY_PARAM]);
    }
  };

export const removeActiveAccessKeyErrorAction = () => (dispatch: AppDispatch) => {
  dispatch(accessKeyDetailsError(''));
};
