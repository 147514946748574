import { type MnvvContent } from '@/ducks/mnvv/types';
import { env } from '@/environment';
import { simpleFetchWithAuth, type TFetchOptions } from '@/features/api/simpleFetch';
import { type TSailingDataResponse } from '@/infra/types/voyageInfo/sailing';

export type TFetchSailingsUrlParams = {
  packageCode: string;
  voyageId: string;
};

export const fetchSailings = (options: TFetchOptions<TFetchSailingsUrlParams>) =>
  simpleFetchWithAuth<TSailingDataResponse>(`${env.REST_BFF_URL}/sailings`, options);

export type TFetchAddonsUrlParams = {
  externalId: string;
};

export type TMnvvAddonsResponse = {
  results: MnvvContent[];
};

export const fetchAddons = (options: TFetchOptions<TFetchAddonsUrlParams>) =>
  simpleFetchWithAuth<TMnvvAddonsResponse>(`${env.CMS_BASE_URL}/addons`, options);
