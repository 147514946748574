import { getSessionStorageValue, removeSessionStorageValue, setSessionStorageValue } from '@/helpers/util/storage';

export const OPTIONS = {
  FULL: 'full',
  HOLD_24_HOURS: 'hold24Hours',
  PREPAYMENT: 'prepayment',

  // ...
};

export const STATUS_CODES = {
  39: '39',
  42: '42',
  49: '49',

  // ...
};

export const MAPPING_STATUS_CODE_TO_OPTIONS = {
  [STATUS_CODES[39]]: OPTIONS.FULL,
  [STATUS_CODES[42]]: OPTIONS.HOLD_24_HOURS,
  [STATUS_CODES[49]]: OPTIONS.PREPAYMENT,
};

export const PAYMENT_KEYS = {
  AMOUNT_PAID: 'paidAmount',
  AMOUNT_TO_PAY: 'amountToPay',
  BOOKING: 'bookNow',
  CLIENT_TRANSACTION_ID: 'clientTransactionId',
  ElIGIBLE_PAYMENT_OPTIONS: 'eligiblePaymentOptions',
  END_RES_ID: 'encResId',
  MAX_FVC_ALLOWED: 'maxFVCAllowed',
  PAYMENT_BOOK_NOW_CALL_FAILED: 'paymentBookNowCallFailed',
  PAYMENT_COMPLETED_TIME: 'paymentCompletedTime',
  PAYMENT_OPTION: 'paymentOption',
  PAYMENT_OPTION_DESC: 'paymentOptionDesc',
  PAYMENT_TOKEN: 'paymentToken',
  RESERVATION_NUMBER: 'reservationNumber',
  STATUS_CODE: 'statusCode',
};

export const getPaymentReservationNumber = () => getSessionStorageValue(PAYMENT_KEYS.RESERVATION_NUMBER);
export const getEncResId = () => getSessionStorageValue(PAYMENT_KEYS.END_RES_ID);
export const getPaymentPaymentOptionDesc = () => getSessionStorageValue(PAYMENT_KEYS.PAYMENT_OPTION_DESC);
export const getPaymentPaymentOption = () => getSessionStorageValue(PAYMENT_KEYS.PAYMENT_OPTION);
export const getPaymentMaxFVCAllowed = () => getSessionStorageValue(PAYMENT_KEYS.MAX_FVC_ALLOWED);
export const getPaymentAmountPaid = () => getSessionStorageValue(PAYMENT_KEYS.AMOUNT_PAID);
export const getPaymentAmountToPay = () => getSessionStorageValue(PAYMENT_KEYS.AMOUNT_TO_PAY);
export const getPaymentStatusCode = () => getSessionStorageValue(PAYMENT_KEYS.STATUS_CODE);
export const getPaymentClientTransactionId = () => getSessionStorageValue(PAYMENT_KEYS.CLIENT_TRANSACTION_ID);
export const getPaymentBooking = () => getSessionStorageValue(PAYMENT_KEYS.BOOKING);
export const getPaymentToken = () => getSessionStorageValue(PAYMENT_KEYS.PAYMENT_TOKEN);
export const getPaymentInfo = () => {
  const clientTransactionId = getPaymentClientTransactionId();
  const statusCode = getPaymentStatusCode();
  const amountToPay = getPaymentAmountToPay();
  const amountPaid = getPaymentAmountPaid();
  const maxFVCAllowed = getPaymentMaxFVCAllowed();
  const paymentOption = getPaymentPaymentOption();
  const paymentOptionDesc = getPaymentPaymentOptionDesc();
  const booking = getPaymentBooking();

  return {
    amountPaid,
    amountToPay,
    booking,
    clientTransactionId,
    maxFVCAllowed,
    paymentOption,
    paymentOptionDesc,
    statusCode,
  };
};
export const getPaymentCompletedTime = () => getSessionStorageValue(PAYMENT_KEYS.PAYMENT_COMPLETED_TIME);
export const getPaymentBookNowCallStatus = () =>
  getSessionStorageValue(PAYMENT_KEYS.PAYMENT_BOOK_NOW_CALL_FAILED) ?? false;
export const getPaymentEligibleOptions = () => getSessionStorageValue(PAYMENT_KEYS.ElIGIBLE_PAYMENT_OPTIONS) ?? [];

export const setPaymentReservationNumber = (value) => setSessionStorageValue(PAYMENT_KEYS.RESERVATION_NUMBER, value);
export const setPaymentPaymentOptionDesc = (value) => setSessionStorageValue(PAYMENT_KEYS.PAYMENT_OPTION_DESC, value);
export const setPaymentPaymentOption = (value) => setSessionStorageValue(PAYMENT_KEYS.PAYMENT_OPTION, value);
export const setPaymentMaxFVCAllowed = (value) => setSessionStorageValue(PAYMENT_KEYS.MAX_FVC_ALLOWED, value);
export const setPaymentAmountPaid = (value) => setSessionStorageValue(PAYMENT_KEYS.AMOUNT_PAID, value);
export const setPaymentAmountToPay = (value) => setSessionStorageValue(PAYMENT_KEYS.AMOUNT_TO_PAY, value);
export const setPaymentStatusCode = (value) => setSessionStorageValue(PAYMENT_KEYS.STATUS_CODE, value);
export const setPaymentClientTransactionId = (value) =>
  setSessionStorageValue(PAYMENT_KEYS.CLIENT_TRANSACTION_ID, value);
export const setPaymentBooking = (value) => setSessionStorageValue(PAYMENT_KEYS.BOOKING, value);
export const setPaymentCompletedTime = (value) => setSessionStorageValue(PAYMENT_KEYS.PAYMENT_COMPLETED_TIME, value);
export const setPaymentBookNowCallStatus = (value = false) =>
  setSessionStorageValue(PAYMENT_KEYS.PAYMENT_BOOK_NOW_CALL_FAILED, value);
export const setPaymentToken = (value) => setSessionStorageValue(PAYMENT_KEYS.PAYMENT_TOKEN, value);
export const setEligiblePaymentOptions = (option) =>
  setSessionStorageValue(PAYMENT_KEYS.ElIGIBLE_PAYMENT_OPTIONS, option);

export const removePaymentReservationNumber = () => removeSessionStorageValue(PAYMENT_KEYS.RESERVATION_NUMBER);
export const removePaymentPaymentOption = () => removeSessionStorageValue(PAYMENT_KEYS.PAYMENT_OPTION);
