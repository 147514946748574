import { type TAuthHeaders, TokenType, withAuthHeader } from '@/helpers/api/tokens/helpers/withToken';
import addToSearchParams from '@/helpers/url/addToSearchParams';
import { type TWithResponseHeaders } from '@/infra/types/api';
import { type TUrlParams } from '@/infra/types/common';

export type TQueryParams = TUrlParams | URLSearchParams;

export type TFetchOptions<P extends TQueryParams = TQueryParams> = RequestInit &
  TWithResponseHeaders & {
    asTextResult?: boolean;
    params?: P;
  };

const simpleFetch = async <R = unknown, P extends TQueryParams = TQueryParams>(
  url: string,
  options: TFetchOptions<P>,
): Promise<R> => {
  const { asTextResult, onResponseHeaders, params, ...rest } = options || {};
  const searchParams = params ? addToSearchParams(new URLSearchParams(), params) : undefined;
  // Note: size property is a read only property of URLSearchParams and inconsistent as sometimes it is undefined.
  // alternate workaround can be searchParams.toString(), Fix: MSH-132437
  const queryParams = searchParams?.toString();
  const requestUrl = queryParams ? `${url}?${queryParams}` : url;
  const response = await fetch(requestUrl, { method: 'GET', ...(rest as RequestInit) });
  onResponseHeaders?.(response.headers);
  if (!response.ok) throw new Error(`Call "${url}" failed: ${response.statusText}`);
  return (await (asTextResult ? response.text() : response.json())) as R;
};

export const simpleFetchWithAuth = withAuthHeader(
  TokenType.guest,
  <R = unknown, P extends TQueryParams = TQueryParams>(
    authHeaders: TAuthHeaders,
    url: string,
    options: TFetchOptions<P>,
  ): Promise<R> => simpleFetch<R, P>(url, { ...options, headers: { ...options?.headers, ...authHeaders } }),
);

export const simpleFetchWithCmsAuth = withAuthHeader(
  TokenType.cms,
  <R = unknown, P extends TQueryParams = TQueryParams>(
    authHeaders: TAuthHeaders,
    url: string,
    options: TFetchOptions<P>,
  ): Promise<R> => simpleFetch<R, P>(url, { ...options, headers: { ...options?.headers, ...authHeaders } }),
);

export default simpleFetch;
